import React from 'react';

import { LoginLayout, MainLayout } from '../layouts';
import {
  ACCOUNT_ROUTE,
  COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE,
  COMMISSION_CLAIM_ROUTE,
  COMMISSION_REPORT_ROUTE,
  DASHBOARD_ROUTER,
  DIVISION_MANAGEMENT_ROUTE,
  MY_WALLET_ROUTE,
  REFERRED_USER_DETAIL_ROUTE,
  REFERRED_USER_LIST_ROUTE,
  SUB_AFFILIATE_DETAIL_ROUTE,
  SUB_AFFILIATE_LIST_ROUTE,
  SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE,
  TEAM_MANAGEMENT_ROUTE,
} from './constants';
import { EUserRole } from '@/constants';

//no auth component
const Login = React.lazy(() => import('@/pages/sign-in'));
const ForgotPassword = React.lazy(() => import('@/pages/forgot-password'));
const ResetPassword = React.lazy(() => import('@/pages/reset-password'));
const ConfirmCode = React.lazy(() => import('@/pages/confirm-code'));
//

//auth component
const Dashboard = React.lazy(() => import('@/pages/referrals/dashboard'));
const DivisionManagement = React.lazy(() => import('@/pages/settings/division-management'));
const TeamManagement = React.lazy(() => import('@/pages/settings/team-management'));
const ReferredUserList = React.lazy(() => import('@/pages/referrals/referred-user-list'));
const ReferredUserDetail = React.lazy(() => import('@/pages/referrals/referred-user-detail'));
const SubAffiliateList = React.lazy(() => import('@/pages/sub-affiliate-accounts/sub-affiliate-list'));
const SubAffiliateDetail = React.lazy(() => import('@/pages/sub-affiliate-accounts/sub-affiliate-detail'));
const OrganizationChart = React.lazy(() => import('@/pages/sub-affiliate-accounts/organization-chart'));
const CommissionReport = React.lazy(() => import('@/pages/commission/commission-report'));
const CommissionClaim = React.lazy(() => import('@/pages/commission/commission-claim'));
const ClaimRequestApproval = React.lazy(() => import('@/pages/commission/claim-request-approval'));

//setting
const Account = React.lazy(() => import('@/pages/settings/account'));
const MyWallet = React.lazy(() => import('@/pages/settings/my-wallet'));

export interface IRoute {
  Component: ((props: any) => JSX.Element) | React.FC<any>;
  Layout: ((props: any) => JSX.Element) | React.FC<any>;
  Protected: boolean;
  isHelpcenter?: boolean;
  path?: string | string[];
  routePath?: string;
  from?: string;
  to?: string;
  exact?: boolean;
  acceptRole?: string[];
}

const noAuthRoute: IRoute[] = [
  {
    Component: Login,
    Layout: LoginLayout,
    exact: true,
    path: '/sign-in',
    routePath: '/sign-in',
    Protected: false,
  },
  {
    Component: ForgotPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/forgot-password',
    routePath: '/forgot-password',
    Protected: false,
  },
  {
    Component: ConfirmCode,
    Layout: LoginLayout,
    exact: true,
    path: '/confirm-code',
    routePath: '/confirm-code',
    Protected: false,
  },

  {
    Component: ResetPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/reset-password',
    routePath: '/reset-password',
    Protected: false,
  },
];

const authRouter: IRoute[] = [
  //Referrals
  {
    Component: Dashboard,
    Layout: MainLayout,
    exact: true,
    path: [DASHBOARD_ROUTER],
    routePath: DASHBOARD_ROUTER,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: ReferredUserList,
    Layout: MainLayout,
    exact: true,
    path: [REFERRED_USER_LIST_ROUTE],
    routePath: REFERRED_USER_LIST_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: ReferredUserDetail,
    Layout: MainLayout,
    exact: true,
    path: [REFERRED_USER_DETAIL_ROUTE],
    routePath: REFERRED_USER_DETAIL_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  //

  //Sub-affiliate accounts
  {
    Component: SubAffiliateList,
    Layout: MainLayout,
    exact: true,
    path: [SUB_AFFILIATE_LIST_ROUTE],
    routePath: SUB_AFFILIATE_LIST_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: SubAffiliateDetail,
    Layout: MainLayout,
    exact: true,
    path: [SUB_AFFILIATE_DETAIL_ROUTE],
    routePath: SUB_AFFILIATE_DETAIL_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  //

  //Commission
  {
    Component: CommissionReport,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_REPORT_ROUTE],
    routePath: COMMISSION_REPORT_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: CommissionClaim,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_CLAIM_ROUTE],
    routePath: COMMISSION_CLAIM_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: ClaimRequestApproval,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE],
    routePath: COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },

  //

  //Settings
  {
    Component: OrganizationChart,
    Layout: MainLayout,
    exact: true,
    path: [SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE],
    routePath: SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: DivisionManagement,
    Layout: MainLayout,
    exact: true,
    path: [DIVISION_MANAGEMENT_ROUTE],
    routePath: DIVISION_MANAGEMENT_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO],
  },
  {
    Component: TeamManagement,
    Layout: MainLayout,
    exact: true,
    path: [TEAM_MANAGEMENT_ROUTE],
    routePath: TEAM_MANAGEMENT_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER],
  },
  {
    Component: Account,
    Layout: MainLayout,
    exact: true,
    path: [ACCOUNT_ROUTE],
    routePath: ACCOUNT_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  {
    Component: MyWallet,
    Layout: MainLayout,
    exact: true,
    path: [MY_WALLET_ROUTE],
    routePath: MY_WALLET_ROUTE,
    Protected: true,
    acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
  },
  //
];

export const routes = [...noAuthRoute, ...authRouter];
