import {
  AccountIcon,
  CardTickIcon,
  DashboardIcon,
  DollarSquareIcon,
  MyWalletMenuIcon,
  PeopleIcon,
  PercentageSquareIcon,
  TagUserIcon,
  UserIcon,
  UserOctagonIcon,
  UserSquareIcon,
} from '@/assets';
import {
  DASHBOARD_ROUTER,
  DIVISION_MANAGEMENT_ROUTE,
  REFERRED_USER_LIST_ROUTE,
  TEAM_MANAGEMENT_ROUTE,
  SUB_AFFILIATE_LIST_ROUTE,
  ACCOUNT_ROUTE,
  MY_WALLET_ROUTE,
  SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE,
  COMMISSION_REPORT_ROUTE,
  COMMISSION_CLAIM_ROUTE,
  COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE,
} from '@/routes/constants';
import { EUserRole } from '@/constants';

export const menuSidebar = [
  {
    id: 1,
    is_group: true,
    title: 'Referrals',
    items: [
      {
        id: 1,
        title: 'Dashboard',
        path: DASHBOARD_ROUTER,
        icon: DashboardIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },

      {
        id: 2,
        title: 'Referred user list',
        path: REFERRED_USER_LIST_ROUTE,
        icon: UserIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
    ],
  },
  {
    id: 2,
    is_group: true,
    title: 'Sub-affiliate accounts',
    items: [
      {
        id: 1,
        title: 'Sub-affiliate list',
        path: SUB_AFFILIATE_LIST_ROUTE,
        icon: TagUserIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
      {
        id: 2,
        title: 'Organization chart',
        path: SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE,
        icon: PeopleIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
    ],
  },
  {
    id: 3,
    is_group: true,
    title: 'Commission',
    items: [
      {
        id: 1,
        title: 'Commission report',
        path: COMMISSION_REPORT_ROUTE,
        icon: PercentageSquareIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
      {
        id: 2,
        title: 'Commission claim',
        path: COMMISSION_CLAIM_ROUTE,
        icon: DollarSquareIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
      {
        id: 3,
        title: 'Claim request approval',
        path: COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE,
        icon: CardTickIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
    ],
  },
  {
    id: 4,
    is_group: true,
    title: 'Settings',
    items: [
      {
        id: 1,
        title: 'Division management',
        path: DIVISION_MANAGEMENT_ROUTE,
        icon: UserOctagonIcon,
        acceptRole: [EUserRole.CEO],
      },

      {
        id: 2,
        title: 'Team management',
        path: TEAM_MANAGEMENT_ROUTE,
        icon: UserSquareIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER],
      },
      {
        id: 3,
        title: 'Account',
        path: ACCOUNT_ROUTE,
        icon: AccountIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
      {
        id: 4,
        title: 'My wallet',
        path: MY_WALLET_ROUTE,
        icon: MyWalletMenuIcon,
        acceptRole: [EUserRole.CEO, EUserRole.DIVISION_LEADER, EUserRole.TEAM_LEADER],
      },
    ],
  },
];
