import queryString from 'query-string';

import {
  EUserRole,
  FormatTime,
  ObjMemberGradeFilter,
  ObjPositionFilter,
  errorCode,
  listPositionSubAffilicate,
  listStatusClaimRequest,
  listStatusSubAffilicate,
} from '@/constants';
import moment from 'moment';

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password: string) => {
  return String(password).match(/^(?=.*\d)(?=.*[A-Z]).{8,30}/);
};

export const handleErrorCode = (response: any) => {
  return errorCode[response.errorCode as keyof typeof errorCode];
};

export const modifyDirectionOrder = (value: string | undefined) => {
  if (value === 'ascend') return 'ASC';
  if (value === 'descend') return 'DESC';
  return '';
};
export const revertDirectionOrder = (value: string) => {
  if (value === 'ASC') return 'ascend';
  return 'descend';
};

export const handleValueFilter = (value: string | undefined) => {
  if (value === 'all') return '';
  return value;
};

export const handleStringify = (obj: any, history: any) => {
  const stringifySymbol = queryString.stringify(obj, { skipEmptyString: true });
  history.push({ pathname: window.location.pathname, search: stringifySymbol });
};

export const formatNumber = (
  value: number,
  valueUnit?: string,
  notSkipEmptyValue?: boolean,
  primary?: boolean,
  numberRounding?: number,
  valueEmpty?: string,
) => {
  if (value && !notSkipEmptyValue) {
    return `${Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: numberRounding ? numberRounding : primary ? 2 : 4,
    })} ${valueUnit ?? ''}`;
  }
  if (notSkipEmptyValue) {
    return `${Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: numberRounding ? numberRounding : primary ? 2 : 4,
    })} ${valueUnit ?? ''}`;
  }
  return valueEmpty ?? '0';
};

let b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''),
  );

export const parseJwt = (token: string) =>
  JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));

export const handleNumber = (value: number | undefined) => {
  if (!value || value === 0) {
    return '';
  }
  if (value > 0) {
    return '+';
  }
};

export const hideEmail = (email: string) => {
  return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      // eslint-disable-next-line no-param-reassign
      gp2 += '*';
    }
    return gp2;
  });
};

export const formatCurrencyToNumber = (value: string) => {
  if (value) {
    return Number(value.replace(/[^0-9.-]+/g, ''));
  }
  return null;
};

export const formatDateTime = (date: string | moment.Moment = '', hasTime: boolean = false) => {
  return (
    date &&
    moment
      .utc(date)
      .local()
      .format(hasTime ? FormatTime.DateFull : FormatTime.Date)
  );
};

export const getPositionNameByPositionKey = (key: string) => {
  const result = listPositionSubAffilicate.find((i) => i.key === key);
  return result?.name;
};
export const getStatusByStatusKey = (key: string) => {
  const result = listStatusSubAffilicate.find((i) => i.key === key);
  return result?.name;
};
export const getStatusClaimRequestByStatusKey = (key: number) => {
  const result = listStatusClaimRequest.find((i) => i.key === key);
  return result;
};
export const getMemberGradeByKey = (key: string) => {
  const result = ObjMemberGradeFilter.items.find((i) => i.key === key);
  return result?.name;
};

export const formatExchange = (arr: string[]) => {
  let text = '';
  arr.forEach((element) => {
    text = text + `${element.charAt(0).toUpperCase() + element.slice(1)}, `;
  });
  return text;
};

export const formatTransactionHash = (hash: string, firstChart = 30, lastChart = 4) => {
  if (!hash) return;

  return `${hash.slice(0, firstChart)}...${hash.slice(-1 * lastChart)}`;
};

export const getPositionFilterByUserPosition = (position: string) => {
  let positionOptions = ObjPositionFilter;
  if (position === EUserRole.DIVISION_LEADER) {
    const findIndex = positionOptions.items.findIndex((item: any) => item.key === EUserRole.DIVISION_LEADER);
    if (findIndex > -1) {
      positionOptions.items.splice(findIndex, 1);
    }
  }
  if (position === EUserRole.TEAM_LEADER) {
    const findIndex = positionOptions.items.findIndex((item: any) => item.key === EUserRole.DIVISION_LEADER);
    if (findIndex > -1) {
      positionOptions.items.splice(findIndex, 1);
    }
    const findTeamLeaderIndex = positionOptions.items.findIndex((item: any) => item.key === EUserRole.TEAM_LEADER);
    if (findTeamLeaderIndex > -1) {
      positionOptions.items.splice(findTeamLeaderIndex, 1);
    }
  }
  return positionOptions;
};

export const removeEmptyAttribute = (obj: any) => {
  const params = {} as any;
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      if (Array.isArray(obj[key]) && obj[key].length) {
        params[key] = encodeURI(obj[key]);
      } else {
        params[key] = obj[key];
      }
    }
    if (typeof obj[key] === 'boolean') {
      params[key] = obj[key];
    }
  });
  return params;
};

export const convertLocalToUTCDate = (date: string, endDate?: boolean) => {
  if (!date) {
    return date;
  }
  let newDate = new Date(date);
  let utcDate = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0);
  if (endDate) {
    utcDate = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59);
  }
  newDate = new Date(utcDate);
  return newDate.toUTCString();
};

export const convertUTCToLocalDate = (date: string, endDate?: boolean) => {
  if (!date) {
    return date;
  }
  let newDate = new Date(date);
  let utcDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 0, 0, 0);
  if (endDate) {
    utcDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 23, 59, 59);
  }
  newDate = new Date(utcDate);
  return newDate;
};
