//settings route
export const SETTINGS = '/settings';
export const DIVISION_MANAGEMENT_ROUTE = SETTINGS + '/division-management';
export const TEAM_MANAGEMENT_ROUTE = SETTINGS + '/team-management';
export const ACCOUNT_ROUTE = SETTINGS + '/account';
export const MY_WALLET_ROUTE = SETTINGS + '/my-wallet';

//referrals route
export const REFERRALS = '/referrals';
export const DASHBOARD_ROUTER = REFERRALS + '/dashboard';
export const REFERRED_USER_LIST_ROUTE = REFERRALS + '/referred-user-list';
export const REFERRED_USER_DETAIL_ROUTE = REFERRED_USER_LIST_ROUTE + '/referred-user-detail';

//Sub-affiliate accounts
export const SUB_AFFILIATE_ACCOUNTS = '/sub-affiliate-accounts';
export const SUB_AFFILIATE_LIST_ROUTE = SUB_AFFILIATE_ACCOUNTS + '/sub-affiliate-list';
export const SUB_AFFILIATE_DETAIL_ROUTE = SUB_AFFILIATE_LIST_ROUTE + '/sub-affiliate-detail';

export const SUB_AFFILIATE_ORGANIZATION_CHART_ROUTE = SUB_AFFILIATE_ACCOUNTS + '/organization-chart';

// Commission
export const COMMISSION = '/commission'
export const COMMISSION_REPORT_ROUTE = COMMISSION + '/commission-report'
export const COMMISSION_CLAIM_ROUTE = COMMISSION + '/commission-claim'
export const COMMISSION_CLAIM_REQUEST_APPROVAL_ROUTE = COMMISSION + '/claim-request-approval'
