import React, { Suspense, useCallback } from 'react';

import { routes } from '@/routes';
import { IsLoggedIn } from '@/store/auth/selector';
import queryString from 'query-string';
import { Redirect, Route, useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { requestUserProfile } from '@/store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
export interface RouteWithLayoutProps {
  component: React.FC<any>;
  layout: React.FC<any>;
  path?: string | string[];
  acceptRole?: string[];
  from?: string;
  to?: string;
  exact?: boolean;
  protect: boolean;
  routePath?: string;
}

export const RouteWithLayout: React.FC<RouteWithLayoutProps> = (props) => {
  const { layout: Layout, component: Component, protect, ...rest } = props;
  const isLoggedIn = useSelector(IsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const { rd } = parsedQuery;
  const token = localStorage.getItem('jwt') as string;
  const decodeToken = token ? (jwt_decode(token) as { type: string }) : ({} as { type: string });

  const getFirstRoute = useCallback(() => {
    const protectedRoute = routes.filter((i) => i.Protected);
    return protectedRoute[0].routePath;
  }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isLoggedIn) {
          dispatch(requestUserProfile());
        }
        if (!protect) {
          if (isLoggedIn) {
            return (
              <Redirect
                to={{
                  pathname: typeof rd === 'string' ? rd : getFirstRoute(),
                }}
              />
            );
          }
          return (
            <Layout>
              <Suspense fallback={<></>}>
                <Component {...matchProps} />
              </Suspense>
            </Layout>
          );
        } else {
          if (isLoggedIn && decodeToken) {
            const hasPermissionAccept = rest.acceptRole?.length && rest.acceptRole.includes(decodeToken.type);
            if (hasPermissionAccept) {
              return (
                <Layout>
                  <Suspense fallback={<></>}>
                    <Component {...matchProps} />
                  </Suspense>
                </Layout>
              );
            }
            return (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                search: `?rd=/${matchProps.location.pathname.slice(1)}`,
                state: { rd: matchProps.location },
              }}
            />
          );
        }
      }}
    />
  );
};
