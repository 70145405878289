import React, { useCallback } from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { commonCloseError } from '@/store/common/actions';
import { ErrorState } from '@/store/common/selector';

import { CloseOrangeIcon } from '@/assets';
import { Dialog } from './Dialog';
import { useTranslation } from 'react-i18next';

export const DialogError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(ErrorState);
  const handleCloseError = () => {
    dispatch(commonCloseError());
    if (!error.action) return;
    if (error.action) {
      dispatch({ type: error.action });
    }
  };
  console.log('error', error);

  const renderTitle = useCallback(() => {
    if (error.errorCode === 'NO_COMMISSION_RATE') {
      return t('No commission rate');
    }
    if (error.errorCode === 'USER_DISABLE') {
      return t('Inactive account');
    }
    return error.message;
  }, [error.errorCode, error.message, t]);
  const renderDescription = useCallback(() => {
    if (error.errorCode === 'NO_COMMISSION_RATE') {
      return t('Before referring users, please get in touch with the CEO to discuss adding your commission rate.');
    }
    if (error.errorCode === 'USER_DISABLE') {
      return t('Your account has been disabled, please contact your manager to reactivate your account');
    }
    return '';
  }, [error.errorCode, t]);

  return (
    <Dialog
      isOpen={error.open}
      handleClose={() => handleCloseError()}
      className="titan-modal"
      wrapClassName="dialog-error"
    >
      <>
        <div className="titan-modal__content center">
          <div className="box-icon">
            <CloseOrangeIcon />
          </div>
          <div className="title ">{renderTitle()}</div>
          <div className="description ">{renderDescription()}</div>
        </div>
        <div className="titan-modal__footer">
          <div className="actions center">
            <Button type="primary" size="large" className="btn-orange" onClick={() => handleCloseError()}>
              Got It
            </Button>
          </div>
        </div>
      </>
    </Dialog>
  );
};
export default DialogError;
