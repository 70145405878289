import React from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from '@/components/RouteWithLayout';

import 'antd/dist/antd.less';
import 'react-datepicker/dist/react-datepicker.css';
import './App.scss';
import { DialogError } from './components';
import './i18n';
import { routes } from './routes';
export default function App() {
  return (
    <>
      <Switch>
        <Redirect from="/" to={'/sign-in'} exact />

        {routes.map(({ Component, Layout, Protected, routePath, exact, path, isHelpcenter, acceptRole }, key) => {
          return (
            <RouteWithLayout
              key={String(key + 1)}
              component={Component}
              exact={exact}
              layout={Layout}
              path={path}
              routePath={routePath}
              protect={Protected}
              acceptRole={acceptRole}
            />
          );
        })}
      </Switch>
      <DialogError />
    </>
  );
}
